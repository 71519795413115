* {
  margin: 0px;
  padding: 0px;
}
p, button, input, textarea, legend, a, *{
  font-family: 'Montserrat', sans-serif;
}
h1{
  font-family: 'Josefin Sans', sans-serif;
}


/* #root{width:100vw; height:100vh;} */
